import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { DataAccess } from '../helpers/dataAccess';
import { ApiCall } from '../services/api-call';

@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.css']
})
export class TrackComponent {
  apiError: boolean = false;
  public errorMessage!: string;
  trackingData: any[] = [];
  tracking: boolean = false;
  dataAccess = new DataAccess();
  spinning: boolean = false;
  waybill!: string;
  waybills!: string;
  waybillss!: string;
  origin!: string ;
  destination!: string;
  status!: string;
  myWaybill!: number;
  dataSource = new MatTableDataSource<any>();
  showTrackDisplay: boolean = false;
  waybillNumberInvoice: any;

  shipmentTrackings: any[] = [];
  waybillwidth = this.dataAccess.getLocalStorageValue("waybillNumber") || null;
  // responsible for table column
  displayedColumns: string[] = [
    "position",
    "waybill",
    "date",
    "time",
    "location",
    "status",
  
  ];
  // paginator
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  constructor(private apiCall: ApiCall, private router: Router, private route: ActivatedRoute,) {

    this.route.params.subscribe(params=>this.waybill = params["waybill"])
 
  }
  closePopUp() {
    this.apiError = false;
  }
  // trackShipment() {
  //   this.spinning = true;
  //   this.apiCall.getShipmentTracking(this.waybill).subscribe((data) => {
  //     this.spinning = true;
  //     if (data.Code == 200) {
  //       this.spinning = false;
  //       this.tracking = true;
  //       this.trackingData = data.Object;
  //     } else {
  //       this.spinning = false;
  //       this.apiError = true;
  //       this.errorMessage = data.ShortDescription;
  //     }
  //     this.dataSource = new MatTableDataSource(this.trackingData);
  //   });
  // }

  // trackShipmentWith(waybill: number) {
  //   this.spinning = true;
  //   this.apiCall.getShipmentTracking(waybill).subscribe((data) => {
  //     this.spinning = true;
  //     if ((data.Code = 200)) {
  //       this.spinning = false;
  //       this.tracking = true;
  //       this.trackingData = data.Object;
  //     } else {
  //       this.spinning = false;
  //       this.apiError = true;
  //       this.errorMessage = data.ShortDescription;
  //     }
  //     this.dataSource = new MatTableDataSource(this.trackingData);
  //   });
  // }
  
  // stores waybill number and navigates to print invoice page
  getWaybill(invoiceId: any) {
    this.waybillNumberInvoice = this.trackingData[invoiceId].Waybill;
    // stores waybill number to local storage
    this.dataAccess.setLocalStorageValue(
      "waybillNumberInvoice",
      this.waybillNumberInvoice
    );
    this.router.navigate(["/mainpage/printInvoice"]);
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const waybillParam = params.get('waybillNumber');
      if (waybillParam) {
        this.waybill = waybillParam;
        this.getToTrackWaybill(); // Auto-fetch if waybill is provided in route
      }

    });    
  
  }

// ***
  getToTrackWaybill(): void {
    if (!this.waybill) {
      this.apiError = true;
      this.errorMessage = 'Please enter a waybill number';
      return;
    }
    this.spinning = true;

    this.apiCall.getTrackShipmentWaybill(this.waybill).subscribe(  
       (data: any) => {
        
         this.spinning = false;
          if (data.Code === "200"){
            this.showTrackDisplay = true;
            const trackingData = data.Object;            
            this.origin = trackingData?.Origin;
            this.destination = trackingData?.Destination;
            this.shipmentTrackings = trackingData?.MobileShipmentTrackings || [];

            for (let i = 0; i < this.shipmentTrackings.length; i++) {

              this.myWaybill = this.shipmentTrackings[i]?.Waybill;
              this.status = this.shipmentTrackings[i]?.ScanStatus.Reason;
            }

            
          // bind data to table
          this.dataSource.data = this.shipmentTrackings.map((tracking: any) => {
            return {
              Waybill: tracking.Waybill,
              DateTime: tracking.DateTime,
              Location: tracking.Location,
              User: tracking.User,
              Status: tracking?.ScanStatus?.Reason || 'No status available',
            };
          })   
          }else{
            this.apiError = true;
            this.errorMessage = data.ShortDescription;
          }
        },
        (error) => {
          this.spinning = false;
          this.apiError = true;
          this.errorMessage = 'An error occurred while tracking the shipment';
          console.error('Error tracking shipment:', error);
        }
    );
  }
}
